<template>
  <v-container
    id="regular-tables"
    fluid
  >
    <v-data-table
      :headers="headers"
      :items="productsList"
      :loading="isProductsListLoading"
      :server-items-length="promotionsMeta.totalItems"
      :options.sync="options"
      :items-per-page="10"
      :footer-props="{ 'items-per-page-options': [10, 30, 50] }"
    >
      <template v-slot:item.images="{ item }">
        <v-img
          v-if="item.image"
          :src="item.image.url"
          max-height="50"
          max-width="50"
        />
        <v-img
          v-else
          src="@/assets/product.png"
          max-height="50"
          max-width="50"
        />
      </template>
      <template v-slot:item.stock="{ item }">
        <span>{{
          item.lots &&
          item.lots.reduce((stock, lot) => stock + lot.qte_in_lot, 0)
        }}</span>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn
          @click="addProduct(item)"
          small
          outlined
          color="primary"
        >
          <v-icon>mdi-plus</v-icon> Add
        </v-btn>
      </template>
      <template v-slot:top>
        <div
          style="gap: 10px"
          class="d-flex mx-2 mt-2"
        >
          <v-text-field
            outlined
            dense
            clearable
            v-model="options.search"
            :label="$t('search')"
            class=""
          ></v-text-field>
          <v-autocomplete
            :items="shelves"
            item-value="id"
            item-text="name"
            outlined
            dense
            clearable
            v-model="options.shelf"
            @change="shelfChanged"
            :label="$t('shelves')"
            class=""
          ></v-autocomplete>
          <v-autocomplete
            :items="currentCategories"
            item-value="id"
            item-text="name"
            outlined
            dense
            clearable
            v-model="options.category"
            @change="categoryChanged"
            :label="$t('category')"
            class=""
          ></v-autocomplete>
          <v-autocomplete
            :items="currentSubCategories"
            item-value="id"
            item-text="name"
            outlined
            dense
            clearable
            v-model="options.sub_category"
            :label="$t('subCategory')"
          ></v-autocomplete>
          <v-autocomplete
            :items="providers"
            item-value="id"
            item-text="name"
            outlined
            dense
            clearable
            v-model="options.provider"
            :label="$t('provider')"
          ></v-autocomplete>
        </div>
      </template>
      <template v-slot:no-data>
        {{ $t("no_data_available") }}
      </template>
    </v-data-table>
    <add-product-modal
      v-if="addedProduct"
      :isAddProductModalVisible="isAddProductModalVisible"
      :toggleAddProductModal="toggleAddProductModal"
      :product="addedProduct"
      :promotion="promotion"
    ></add-product-modal>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import debounce from "lodash/debounce";

export default {
  props: {
    validatePromotion: Function,
  },
  components: {
    AddProductModal: () => import("../modals/AddProductModal.vue"),
  },

  computed: {
    ...mapGetters({
      isProductsListLoading: "promotions/isProductsListLoading",
      productsList: "promotions/productsList",
      promotionsMeta: "promotions/meta",
      hubs: "hubs/activeHubs",
      shelves: "categories/listShelves",
      providers: "providers/listProviders",
      promotion: "promotions/promotion",
    }),

    hubs() {
      return this.promotion.hubs;
    },
  },

  watch: {
    options: {
      handler: debounce(async function (params) {
        if (!this.isVisibleDialog) {
          await this.$store
            .dispatch("promotions/listProducts", {
              ...params,
              hubs: this.promotion?.hubs ?? [],
            })
            .then(() => {})
            .catch(() => {
              this.$store.dispatch("alerts/error", "Could not fetch products");
            });
        }
      }, 1000),
      deep: true,
    },

    hubs: {
      handler: function (params) {
        this.loadProducts(params);
      },
      deep: true,
    },
  },

  data() {
    return {
      search_to_hub: "",
      search_from_provider: "",
      is_loading_providers: false,
      is_loading_hubs: false,
      options: {},
      index: 0,
      is_loading: false,
      is_loading_invoice: false,
      is_loading_dn: false,
      isAddProductModalVisible: false,
      addedProduct: null,
      currentCategories: [],
      currentSubCategories: [],
      headers: [
        {
          text: "",
          align: "start",
          sortable: false,
          value: "images",
        },
        {
          text: this.$t("name"),
          align: "start",
          sortable: true,
          value: "name",
        },
        {
          text: this.$t("product_id"),
          align: "start",
          sortable: true,
          value: "id",
        },

        {
          text: this.$t("sku"),
          align: "start",
          sortable: false,
          value: "sku",
        },
        {
          text: this.$t("price"),
          align: "start",
          sortable: false,
          value: "price",
        },

        {
          text: this.$t("stock"),
          value: "stock",
          align: "start",
          sortable: true,
        },
        {
          text: this.$t("actions"),
          value: "actions",
          align: "end",
          sortable: false,
        },
      ],
    };
  },

  methods: {
    loadProducts(params) {
      if (!this.isVisibleDialog) {
        this.$store
          .dispatch("promotions/listProducts", {
            ...params,
            hubs: this.promotion?.hubs,
          })
          .then(() => {})
          .catch(() => {
            this.$store.dispatch("alerts/error", "Products not found");
          });
      }
    },
    debounceProductLoad() {
      debounce(function (params) {
        this.loadProducts(params);
      }, 1000);
    },
    toggleAddProductModal() {
      if (this.isAddProductModalVisible) {
        this.addedProduct = null;
      }
      this.isAddProductModalVisible = !this.isAddProductModalVisible;
    },
    async addProduct(item) {
      if (!this.promotion.id) {
        try {
          if (this.validatePromotion()) {
            await this.$store.dispatch("promotions/submit", this.promotion);
          } else {
            return;
          }
        } catch (error) {
          this.$store.dispatch("alerts/error", error.response?.data?.message);
          return;
        }
      }
      this.addedProduct = item;
      this.toggleAddProductModal();
    },
    shelfChanged(shelf_id) {
      this.currentCategories = this.shelves.find(
        (shelf) => shelf.id === shelf_id
      )?.children;
      this.currentSubCategories = [];
      this.options.category = null;
      this.options.sub_category = null;
    },
    categoryChanged(category_id) {
      this.currentSubCategories = this.currentCategories.find(
        (category) => category.id === category_id
      )?.children;
      this.options.sub_category = null;
    },
  },
  mounted() {
    this.$store.dispatch("categories/listShelves");
    this.$store.dispatch("providers/list");
  },
};
</script>
